
import { Component, Vue } from 'vue-property-decorator'
import { LostTable, LostInfo } from '@/types/lostGoods.d'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { idCardNo } from '@/utils/validate'
import UploadFile from '@/components/uploadFile/Index.vue'
import Config from './Config.vue'

@Component({
  name: 'LostList',
  components: { UploadFile, Config }
})
export default class LostList extends Vue {
  private searchInfo = {
    projectName: '',
    goodsName: '',
    claimStatus: ''
  }

  private info: LostInfo = {
    projectId: '',
    goodsName: '',
    resourceList: []
  }

  private claimInfo = {
    id: '',
    claimName: '',
    claimTel: '',
    claimCard: ''
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    goodsName: [
      { required: true, message: '请输入物品名称', trigger: ['blur', 'change'] }
    ],
    resourceList: [
      { required: true, message: '请上传物品照片', trigger: ['blur', 'change'] }
    ]
  }

  private claimRules = {
    claimName: [
      { required: true, message: '请输入认领人姓名', trigger: ['blur', 'change'] }
    ],
    claimTel: [
      { required: true, message: '请输入认领人手机号码', trigger: ['blur', 'change'] }
    ],
    claimCard: [{ validator: idCardNo, trigger: ['blur', 'change'] }]
  }

  private submitShow = false
  private claimSubmitShow = false
  private addShow = false
  private claimShow = false
  private configShow = false

  private loading = false
  private tableData: LostTable[] = []

  private page = 1
  private size = 10
  private total = 0
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
  }

  // 查询
  onQuery () {
    this.page = 1
    this.getData()
  }

  // 表格
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: LostTable[] }>(this.$apis.lostGoods.selectLostGoodsByPage,
      {
        page: this.page,
        size: this.size,
        ...this.searchInfo
      }
    )
      .then((res) => {
        if (res) {
          this.tableData = res.list || []
          this.total = res.total || 0
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  onAdd () {
    this.addShow = true
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.lostGoods.insertLostGoods, this.info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.onQuery()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'lostGoodsDetail',
      params: { id }
    })
  }

  onClaim (id: string) {
    this.claimInfo.id = id
    this.claimShow = true
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  // 关闭
  closeDialog () {
    this.addShow = false
    this.info = {
      projectId: '',
      goodsName: '',
      resourceList: []
    }
    this.$nextTick(() => {
      (this.$refs.info as ElForm).clearValidate()
    })
  }

  // 认领关闭
  claimClose () {
    this.claimShow = false
    this.claimInfo = {
      id: '',
      claimName: '',
      claimTel: '',
      claimCard: ''
    }
    this.$nextTick(() => {
      (this.$refs.claimInfo as ElForm).clearValidate()
    })
  }

  // 认领提交
  claimSubmit () {
    (this.$refs.claimInfo as ElForm).validate(valid => {
      if (valid) {
        this.claimSubmitShow = true
        this.$axios.post(this.$apis.lostGoods.updateLostGoods, {
          claimStatus: '1',
          ...this.claimInfo
        }).then(() => {
          this.$message.success('保存成功')
          this.claimClose()
          this.onQuery()
        }).finally(() => {
          this.claimSubmitShow = false
        })
      }
    })
  }
}
