
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ConfigTable, Info } from '@/types/lostGoods'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import { drawPolygon } from '@/utils/formatData'

const icon = require('@/assets/icon/common/icon_map_shiwuzhaoling.svg')
@Component
export default class Type extends Vue {
  @Prop() readonly configShow!: boolean;
  private tableData: ConfigTable[] = []
  private info: Info = {
    id: '',
    resourcesLocationList: []
  }

  private projectId = ''
  private inputShow = false
  private loading = false
  private mapShow = false
  private map: AMap.Map | any = null
  private marker: AMap.Marker | any = null
  private ploygons: Array<AMap.Polygon> = []
  private projectList = []

  @Watch('configShow')
  configShowChange (value: boolean) {
    value && this.getData()
  }

  created () {
    this.getProjectList()
  }

  destroyed (): void {
    if (this.map.getAllOverlays('marker').length > 0) {
      this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
        item.off('click', () => {
          // 清除marker事件
        })
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    if (this.map) {
      this.map.off('click', null)
      this.map.destroy()
    }
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<ConfigTable> }>(this.$apis.lostGoods.selectClaimPlacesByPage).then(res => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
      this.inputShow = false
    })
  }

  getProjectList () {
    this.$axios.get(this.$apis.lostGoods.selectProjectList).then(res => {
      this.projectList = res || []
    })
  }

  onAdd () {
    this.getProjectList()
    this.inputShow = true
    this.tableData.push({
      projectId: '',
      claimPlaceTel: '',
      resourcesLocationList: [],
      isInput: true
    })
    this.$nextTick(() => {
      (this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs.table as any).bodyWrapper.scrollHeight
    }) // 滚动到表格最底部
  }

  onSubmit (row: ConfigTable) {
    if (row.projectId && row.claimPlaceTel && this.info.resourcesLocationList.length > 0) {
      console.log(this.info.resourcesLocationList)
      const info = {
        projectId: row.projectId,
        claimPlaceTel: row.claimPlaceTel,
        resourcesLocationList: this.info.resourcesLocationList
      }

      this.$axios.post(this.$apis.lostGoods.insertClaimPlaces, info).then(() => {
        this.$message.success('保存成功')
        this.getData()
      })
    } else {
      this.$message({
        message: '请填写完整并打点',
        type: 'warning'
      })
    }
  }

  // 取消
  onCancel (scope: {$index: number}) {
    this.inputShow = false
    this.tableData.splice(scope.$index, 1)
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.lostGoods.deleteDataClaimPlaces, {
          id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 地图保存
  onMapSubmit () {
    if (this.info.resourcesLocationList.length > 0) {
      if (this.info.id) {
        this.$axios.post(this.$apis.lostGoods.updateClaimPlaces, this.info).then(() => {
          this.mapShow = false
          this.getData()
        })
      } else {
        this.mapShow = false
      }
    } else {
      this.$message({
        message: '请打点',
        type: 'warning'
      })
    }
  }

  // 地图打开
  onMap (row: any) {
    this.info = {
      id: row.id || '',
      resourcesLocationList: row.resourcesLocationList || []
    }
    this.projectId = row.projectId || ''
    this.mapShow = true
    this.loadMap()
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.ploygons = drawPolygon(this.map, res.projectLocation, null, (e: any) => {
        this.markerAdd([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  // 地图初始化
  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.map = new AMap.Map('map', {
        zoom: 11,
        doubleClickZoom: false
      })
      this.projectId && this.getProjectLocation() // 获取电子围栏
      if (this.info.resourcesLocationList.length > 0) {
        this.markerAdd([this.info.resourcesLocationList[0].longitude, this.info.resourcesLocationList[0].latitude])
      }
      this.map.on('click', (e: any) => {
        this.markerAdd([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  markerAdd (position: [number, number]) {
    // 删除上一个点
    if (this.marker) {
      this.marker.setMap(null)
      this.marker = null
    }
    this.marker = new AMap.Marker({
      icon: new AMap.Icon({
        // 图标的取图地址
        image: icon
      }),
      offset: new AMap.Pixel(-20, -40),
      position: [Number(position[0]), Number(position[1])]
    })
    this.map.add(this.marker)
    this.info.resourcesLocationList = [{
      longitude: position[0],
      latitude: position[1]
    }]
    console.log(this.info.resourcesLocationList)
  }

  // 地图关闭
  closeDialogMap () {
    console.log('这批')
    this.mapShow = false
    this.info = {
      id: '',
      resourcesLocationList: []
    }
  }

  // 关闭
  closeDialog () {
    this.$emit('update:configShow', false)
  }
}
