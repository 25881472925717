
import { Component, Vue } from 'vue-property-decorator'
import { SeekTable } from '@/types/lostGoods.d'

@Component({
  name: 'SeekList',
  filters: {
    statusFilters (value: string) {
      return value === '0' ? '寻找中' : value === '1' ? '已找回' : value === '2' ? '已取消' : ''
    }
  }
})
export default class SeekList extends Vue {
  private searchInfo = {
    projectName: '',
    goodsName: '',
    goodsStatus: ''
  }

  private loading = false
  private tableData: SeekTable[] = []

  private page = 1
  private size = 10
  private total = 0

  created (): void {
    this.getList()
  }

  // 查询
  onQuery () {
    this.page = 1
    this.getList()
  }

  // 表格
  getList () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: SeekTable[] }>(
        this.$apis.lostGoods.selectLostGoodsApplyByPage,
        {
          page: this.page,
          size: this.size,
          ...this.searchInfo
        }
      )
      .then((res) => {
        if (res) {
          this.tableData = res.list || []
          this.total = res.total || 0
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 置为已找回
  onRetrieve (id: string) {
    this.$confirm('确认置为已找回吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.lostGoods.updateLostGoodsApply, {
        id,
        goodsStatus: '1'
      }).then(() => {
        this.$message.success('保存成功')
        this.getList()
      })
    })
  }
}
